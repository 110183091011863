import {EoxLabels, EoxSentinel2Cloudless2018, EoxStreets} from "../layers";
import {TerramonitorFusion, TerramonitorLandCover} from "../layers/terramonitor";
import {CopernicusCorineLandCover2018} from "../layers/copernicus";
import TerramonitorWMTS, {createTerramonitorLayer} from "../layers/terramonitor/TerramonitorWMTS";
import {faBullseye} from "@fortawesome/free-solid-svg-icons";

/* global WorldWind */

const config = {
  features: {
    search: false,
  },
  view: {
    latitude: 58.3780,
    longitude: 26.7290,
    altitude: 5e3,
    projection: "MERCATOR",
  },
  limits: {
    sector: new WorldWind.Sector(57.3780, 59.3780, 25.7290, 27.7290),
    maxAltitude: 1e6,
  },
  links: [
    {
      icon: faBullseye,
      title: 'Traffic information',
      href: 'http://35.217.28.71/esttraffic/',
    },
  ]
};

export default config;

export const layers = [
  /**
   * Basemap layers
   */
  {layer: new EoxSentinel2Cloudless2018(), options: {category: 'base', enabled: false}},
  {layer: createTerramonitorLayer("rgb"), options: {category: 'base', enabled: true}},

  /**
   * Configuration layers
   */
  {layer: 'coordinates', options: {category: 'setting', enabled: true}},

  /**
   * Atmosphere layers
   */
  {layer: createTerramonitorLayer("clc"), options: {category: 'atmosphere', enabled: false, opacity: 0.8}},
  {layer: createTerramonitorLayer("so2_monthly"), options: {category: 'atmosphere', enabled: false, opacity: 0.8}},
  {layer: createTerramonitorLayer("no2_monthly"), options: {category: 'atmosphere', enabled: false, opacity: 0.8}},
  {layer: createTerramonitorLayer("ch4_monthly"), options: {category: 'atmosphere', enabled: false, opacity: 0.8}},
  {layer: createTerramonitorLayer("lst_evening"), options: {category: 'atmosphere', enabled: false, opacity: 0.8}},
  {layer: createTerramonitorLayer("lst_morning"), options: {category: 'atmosphere', enabled: false, opacity: 0.8}},
];
