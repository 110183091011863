import React, {Component} from 'react'
import Globe from './globe'
import {CardColumns, Container} from 'reactstrap'
import {
  faCamera,
  faChartArea, faCubes,
  faInfoCircle,
  faLayerGroup,
  faLeaf,
  faMountain,
  faUniversity
} from '@fortawesome/free-solid-svg-icons'

import LayersCard from './bootstrap/LayersCard'
import NavBar from './bootstrap/NavBar'
import NavBarItem from './bootstrap/NavBarItem'
import InformationCard from "./bootstrap/InformationCard";
import SearchBox from "./bootstrap/SearchBox";

import './App.css'
import config, {layers} from "./config/config";
import LinkCard from "./bootstrap/LinksCard";
import NavBarLink from "./bootstrap/NavBarLink";

export default class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      latitude: config.view.latitude,
      longitude: config.view.longitude,
      altitude: config.view.altitude,
      globe: null
    };

    this.globeRef = React.createRef();

    this.navBarRef = React.createRef();
    this.basemapRef = React.createRef();
    this.atmosphereRef = React.createRef();
    this.linksRef = React.createRef();
  }

  componentDidMount() {
    // Get the component with the WorldWindow after mounting
    this.setState({globe: this.globeRef.current})
  }

  render() {
    const globe = this.globeRef.current;

    const closingCards = [
      this.navBarRef.current,
      this.basemapRef.current,
      this.atmosphereRef.current,
      this.linksRef.current
    ];

    const navbarItems = [
      <NavBarItem key='basemap' title='Basemap' icon={faCamera} card={this.basemapRef.current} closingCards={closingCards}/>,
      <NavBarItem key='atmosphere' title='Data layers' icon={faLayerGroup} card={this.atmosphereRef.current} closingCards={closingCards}/>,
      <NavBarItem key='links' title='Dashboards' icon={faChartArea} card={this.linksRef.current} closingCards={closingCards}/>,
      <NavBarLink title='3D Models' icon={faCubes} href={'https://www.dronedeploy.com/app2/data/5fb78aec6bb1f365d95d6005;jwt_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJleHAiOjI1MzQwMjMwMDc5OSwiaWQiOiI1ZmI3OGFlYzZiYjFmMzY1ZDk1ZDYwMDUiLCJzY29wZSI6WyIxNjBkOWViYWIzXzNEQzE2NEQzQkZJTlNQSVJFIl0sInR5cGUiOiJSZWFkT25seVBsYW4ifQ.emQBMuEjk3Hhb4X7DTYm5MTyZOgHU4iwBqLoHjM_eVfkk0l3S2vRWyBdXeQO9SAOgKSpblSqJcohakBcrw9Slw'}/>
    ];

    //navbarItems.push(config.links.map(link => <NavBarLink title={link.title} icon={link.icon} href={link.href}/>));

    const navbarSearch = config.features.search && <SearchBox globe={globe} mapQuestApiKey="9lveC1YtIIomMPiqyEHAIWwjyx8xrdAL"/>;

    return (
      <div>
        <NavBar
            ref={this.navBarRef}
            logo='./logo.png'
            title=''
            items={navbarItems}
            search={navbarSearch}/>

        <Container fluid className='p-0'>
          <div className='globe'>
              <Globe
                ref={this.globeRef}
                layers={layers}
                latitude={this.state.latitude}
                longitude={this.state.longitude}
                altitude={this.state.altitude}
                maxAltitude={config.limits.maxAltitude}
                sector={config.limits.sector}/>
          </div>
          <div className='overlayCards noninteractive'>
            <CardColumns>
              <LayersCard
                ref={this.basemapRef}
                title='Basemap'
                icon={faCamera}
                categories={['base']}
                globe={globe} />
              <LayersCard
                ref={this.atmosphereRef}
                title='Data layers'
                icon={faLayerGroup}
                categories={['atmosphere']}
                globe={globe} />
              <InformationCard
                  ref={this.informationRef}
                  title={'Information'}
                  icon={'infoCircle'}
                  globe={globe}/>
              <LinkCard
                ref={this.linksRef}
                title={'Dashboards'}
                icon={faChartArea}
                links={config.links}/>
            </CardColumns>
          </div>
        </Container>
      </div>
    )
  }
}
